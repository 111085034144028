<template>
  <div>
    <!-- Pages size and searching filters -->
    <div class="d-flex justify-content-between align-items-center mx-0 row w-100">
      <div class="col-sm-12 col-md-2">
        <div class="dataTables_length" id="DataTables_Table_0_length">
          <div class="d-flex align-items-center gap-2">
            <span class="d-block"> Show </span>
            <select
              name="DataTables_Table_0_length"
              aria-controls="DataTables_Table_0"
              class="custom-select"
              v-model="length"
            >
              <option
                v-for="(item, index) in per_page"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
            <span class="d-block"> entries </span>
          </div>
        </div>
      </div>
      <div class="col">
        <slot name="filters"></slot>
      </div>
      <div class="col-sm-12 col-md-3">
        <div id="DataTables_Table_0_filter" class="dataTables_filter">
          <label>Search: </label>
          <input
            type="search"
            v-model="search"
            class="form-control"
            placeholder="Search ..."
            aria-controls="DataTables_Table_0"
            v-on:input="updateRecordsBySearching"
          />
        </div>
      </div>
    </div>

    <slot name="table"></slot>
    <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
    <AlertWidget v-if="totalRecords.length <= 0 && loading==false" class="mt-4" text="No Record Found"></AlertWidget>
    <!-- Pagination -->
    <div class="row mt-3 align-items-center">
      <div class="col-sm-12 col-md-6">
        <div
          class="dataTables_info"
          id="DataTables_Table_0_info"
          role="status"
          aria-live="polite"
        >
          Showing {{pagination.from}} to {{ pagination.to ?? 0 }} of {{ pagination.total }} entries
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination justify-content-end py-0 my-0 gap-2">
            <li
              class="paginate_button page-item previous"
              :class="pagination.prevPageUrl == null ? 'disabled' : ''"
            >
              <button
                type="button"
                :disabled="pagination.prevPageUrl == null ? true : false"
                @click="getRecords(pagination.prevPageUrl)"
                class="btn bg-light text-dark"
              >
                <i class="fas fa-arrow-left"></i>
              </button>
            </li>

            <li
              class="paginate_button page-item next"
              :class="pagination.nextPageUrl == null ? 'disabled' : ''"
            >
              <button
                type="button"
                :disabled="pagination.nextPageUrl == null ? true : false"
                @click="getRecords(pagination.nextPageUrl)"
                aria-controls="DataTables_Table_0"
                data-dt-idx="8"
                tabindex="0"
                class="btn bg-light text-dark"
              >
                <i class="fas fa-arrow-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import AlertWidget from "@/components/general/AlertWidget";
export default {
  props: ["fetch_url_prop", "extraFilters"],

  data() {
    return {
      per_page: ["5", "10", "20", "50", "100"],
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      table_data: {
        draw: 0,
        length: 5,
        search: null,
      },

      length: 5,
      search: "",
      loading:false,
      totalRecords: [],
    };
  },

  components:{
    AlertWidget,
  },

  watch: {
    length: {
      handler: function (length, prev_length) {
        if (length) {
          this.updateRecordsByLength(length);
        }
      },
      deep: true,
      immediate: true,
    },
    /* comment this watcher becuase we doesn't need it when we call a debounce using v-on:input*/
    // search: {
    //   handler: function (search, prev_search) {
    //     console.log(prev_search , 'prev');
    //     console.log(search , 'new');
    //     if (search) {
    //       this.updateRecordsBySearching(search);
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },

  mounted() {
    this.getRecords();
  },

  methods: {
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },

    updateRecordsByLength(length) {
      this.table_data.length = length;
      this.getRecords();
    },

    // updateRecordsBySearching(search) {
    //   this.table_data.search = search;
    //   this.getRecords();
    // },

    updateRecordsBySearching: _.debounce(function(search) {
        this.table_data.search = search.target.value;
        this.getRecords();  
    }, 400),

    getRecords(url = this.fetch_url_prop) {
      if (this.extraFilters != null) {
        for (const [key, value] of Object.entries(this.extraFilters)) {
          this.table_data[key] = value;
        }
      }
      this.loading = true;
      this.table_data.draw++;
      this.$axios
        .get(url, { params: this.table_data })
        .then((response) => {
          let payload = response.data.payload;
          if (this.table_data.draw == payload.draw) {
            this.configPagination(payload.paginated);

            //send data to parent for processing
            this.$emit("recordsUpdated", payload.paginated.data);
          }
          this.totalRecords = payload.paginated.data;
          this.loading = false;
        })
        .catch(error => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() =>  {
            this.loading = false;
        });
    },
  },
};
</script>
