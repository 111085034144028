<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import MagicTable from "@/components/MagicTable/MagicTable";
import sidebar from "../components/sidebar.vue";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Shifts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MagicTable,
    // sidebar,
  },
  data() {
    return {
      isClick: true, //make it false to enable sidebar
      title: "Shift Names",
      fetch_url: "/shift/name/index",
      items: [
        {
          text: "Shift Names",
        },
        {
          text: "Shift Listing",
          active: true,
        },
      ],
      shiftNames: [],
      renderTable: true,
      extraFilters: {},
    };
  },

  middleware: "authentication",

  methods: {
    updateRecords(records) {
      this.shiftNames = records;
    },

    deleteShiftName(id){
        this.$axios.delete('/shift/name/delete/' + id )
        .then(response => {
            let data = response.data;
            this.triggerSwal(data.message, "success");
            this.shiftNames = this.shiftNames.filter((item) => item.id != id);
            this.processing = false;
        }).catch(error => {
            this.handleErrorResponse(error.response, "error");
        }).finally(() => {
            this.processing = false;
        });  
    }
  },
};
</script>

<template>
  <Layout :dynamicClass="'container-fluid'">
    <div id="section">
      <div id="place_content" class="row">
        <div id="modal-parent">
          <div class="row">
            <div class="col-xl-10" :class="{ 'col-xl-12': isClick }">
              <PageHeader :items="items" :title="title" />
              <div class="card">
                <div class="card-body" id="listing_parent_card">
                <router-link to="/create-shift-name" ><b-button class="gradientColor border-0 radius-20 px-4 float-end"> Add Shift Name </b-button></router-link>
                  <magic-table
                    id="model-content"
                    v-if="renderTable"
                    :fetch_url_prop="fetch_url"
                    :extraFilters="extraFilters"
                    @recordsUpdated="updateRecords"
                    ref="shift_listing_tbl"
                  >
                    <template v-slot:table>
                      <div class="row mt-4">
                        <div class="col-lg-12">
                          <div class="table-responsive table-nowrap">
                            <table class="table mb-0 table-bordered">
                              <thead>
                                <tr>
                                  <th class="f-14 fw-semiBold roboto text-dark">
                                    Name
                                  </th>
                                  <th class="f-14 fw-semiBold roboto text-dark">
                                    Updated At
                                  </th>
                                  <th class="f-14 fw-semiBold roboto text-dark">
                                    Created At
                                  </th>
                                  <th class="f-14 fw-semiBold roboto text-dark">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-if="shiftNames.length > 0">
                                  <tr
                                    v-for="(shift, key) in shiftNames"
                                    :key="key"
                                    class="align-middle"
                                  >
                                    <td scope="row" class="f-14 roboto pColor">
                                      {{ shift.name }}
                                    </td>

                                    <td scope="row" class="f-14 roboto pColor">
                                     {{ DateWithMonthName(shift.updated_at , 'ddd, MMM DD,YYYY hh:mm A')}}
                                    </td>

                                    <td scope="row" class="f-14 roboto pColor">
                                      {{ DateWithMonthName(shift.created_at , 'ddd, MMM DD,YYYY hh:mm A')}}
                                    </td>

                                    <td class="f-14 roboto align-middle">
                                      <ul class="d-flex list-inline mb-0">
                                        <li class="list-inline-item">
                                          <router-link
                                            v-b-tooltip.hover
                                            title="Edit"
                                            :to="{
                                              name: 'edit_shift_name',
                                              params: { shiftName_id: shift.id },
                                            }"
                                          >
                                            <i
                                              class="uil uil-pen font-size-18"
                                            ></i>
                                          </router-link>
                                        </li>
                                        <li class="list-inline-item">
                                          <a
                                            href="javascript:void(0);"
                                            class="px-2 text-danger"
                                            v-b-tooltip.hover
                                            @click="deleteShiftName(shift.id)"
                                            title="Delete"
                                          >
                                            <i
                                              class="uil uil-trash-alt font-size-18"
                                            ></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </template>
                  </magic-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
